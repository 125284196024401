.pageHeader {
  margin-top: 25px;
}

.introImage {
  width: 100vw;
  height: 85vh;
  margin-top: 25px;
  object-fit: cover;
}

.introImageMobile {
  width: 100vw;
  height: 40vh;
  margin-top: 25px;
  object-fit: contain;
}

.sectionView {
  margin-bottom: 80px;
}

.contentView {
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 15%;
  padding-right: 15%;
}

.contentRow {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.introCol {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.introTitle {
  font-weight: 700;
  font-size: 40px;
}

.introSubtitle {
  font-size: 20px;
  font-weight: 600;
  color: #000000;
}

.mobileGap {
  margin-bottom: 20px;
}

.specCol {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin-left: 100px;
}

.fullColumn {
  width: 100%;
}

.titleColumn {
  width: 20%;
}

.contentColumn {
  width: 80%;
}

.titleText {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

.subtitleText {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 2px;
}

.detailText {
  font-size: 18px;
  margin-bottom: 20px;
}

.focusText {
  font-size: 18px;
}

.focusBox {
  width: 100%;
  background-color: #EBEBEB;
  padding: 3%;
  border-radius: 5px;
  margin-bottom: 20px;
}

.imageBox {
  width: 100%;
  aspect-ratio: 2.26;
  object-fit: cover;
  margin-bottom: 20px;
}