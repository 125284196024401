.footer-container {
  margin: 0 60px;
  display: flex;
  flex-direction: column;
  border-bottom: solid 1.5px #ebebeb;
  margin-bottom: 100px;
}

.footer-heading {
  padding: 100px 0 32px 0;
}

.contact-list {
  display: flex;
  gap: 20px;
  list-style-type: none;
  margin: 24px 0 0 0;
  padding: 10px 0 100px 0;
  font-size: 1.2rem;
}

.footer-item {
  padding: 8px 12px;
  border-radius: 20px;
  display: inline-block;
  transition: all 250ms;
}

.footer-item:hover {
  background-color: #1a1a1a;
  color: white;
  transform: translateX(-4%) translateY(7%) rotate(-4deg);
}

.even:hover {
  transform: translateX(2%) translateY(-10%) rotate(3deg);
}

@media only screen and (max-width: 1224px) {

  .footer-container {
    align-items: center;
  }

  .footer-heading {
    padding: 100px;
    text-align: center;
  }
  
}