.header-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 80px 20px;
  background-color: #f4f4f4;
}

.name-logo {
  max-width: 150px;
  margin-top: 20px;
}

.header-logo {
  max-width: 40px;
  margin-top: 20px;
}

.floating {
  z-index: 10;
  position: fixed;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  background: hsla(0, 0%, 100%, 0.7);
  backdrop-filter: blur(8px);
  border-radius: 30px;
}

.header-menu-list {
  font-weight: 600;
  display: flex;
  gap: 8px;
  padding: 8px;
}

.header-menu-item {
  list-style-type: none;
  padding: 8px 0px;
  cursor: pointer;
}

.header-menu-link {
  transition: all 250ms ease;
  padding: 8px 12px;
  border-radius: 20px;
}

.header-menu-link:hover {
  background-color: #ebebeb;
  color: #1a1a1a;
}

.header-menu-current {
  background-color: #1a1a1a;
  color: white;
}

@media only screen and (max-width: 1224px) {

  .name-logo, .header-logo {
    visibility: hidden;
  }
  
}