@import url("satoshi.css");

* {
  font-family: "Satoshi-Regular";
  color: #1a1a1a;
}

body {
  margin: 0;
  background-color: white;
}

h1 {
  font-family: "Satoshi-Bold";
  font-size: 3.4rem;
  margin: 0px;
}

h3 {
  font-size: 1.8rem;
  margin: 5px 0;
}

a {
  text-decoration: none;
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 80px;
  margin: 0 0 150px 0;
}

.hero-intro {
  font-size: 1.7rem;
  width: 50%;
  font-weight: 500;
}

.large {
  background-color: #f4f4f4;
  transition: 500ms;
  border-radius: 0 0 20px 20px;
}

.small {
  background-color: #f4f4f4;
  border-radius: 0 0 40px 40px;
  margin: 0 40px;
}

.content {
  margin: 0 20px;
  padding-bottom: 5px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 5fr);
  gap: 20px;
  margin-bottom: 20px;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  cursor: pointer;
}

.card-1 {
  grid-column: span 4;
  aspect-ratio: 0.8;
  border-radius: 20px;
}

.card-2 {
  grid-column: span 8;
  border-radius: 20px;
}

.card-3 {
  grid-column: span 8;
  border-radius: 20px;
}

.card-4 {
  grid-column: span 4;
  aspect-ratio: 1;
  border-radius: 20px;
}

.card-5 {
  grid-column: span 4;
  grid-row: span 2;
  aspect-ratio: 1.2;
  border-radius: 20px;
}

.card-6 {
  grid-column: 5 / span 4;
  grid-row: 3 / span 3;
  aspect-ratio: 0.8;
  border-radius: 20px;
}

.card-7 {
  grid-column: span 4;
  grid-row: span 3;
  aspect-ratio: 0.8;
  border-radius: 20px;
}

.card-info {
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  margin: 18px;
  position: relative;
  left: 0px;
  bottom: -10px;
  z-index: 1;
  transition: 0.4s cubic-bezier(0.25, 0.1, 0, 2.05);
}

.card-info:hover {
  opacity: 1;
  left: 0;
  bottom: 0px;
}

.card-head {
  display: flex;
}

.card-title {
  font-family: "Satoshi-Medium";
  font-size: 1.1rem;
  background-color: #ebebeb;
  border-radius: 45px;
  margin: 6px 6px 0 0;
  padding: 12px 20px;
}

.card-button {
  display: flex;
  align-items: center;
  background-color: #ebebeb;
  border-radius: 45px;
  margin: 6px 6px 0 0;
  padding: 12px 17px;
}

.card-desc {
  font-size: 1.1rem;
  background-color: #ebebeb;
  border-radius: 20px;
  margin: 6px 0 0 0;
  padding: 12px 20px;
}

.card-2 .card-desc {
  max-width: 60%;
}

.card-3 .card-desc {
  max-width: 60%;
}

.card-roles {
  display: inline-block;
  font-size: 1.1rem;
  background-color: hsla(0, 0%, 100%, 0.7);
  backdrop-filter: blur(8px);
  border-radius: 45px;
  margin: 6px 6px 0 0;
  padding: 12px 20px;
}

.CTA {
  background-color: #ebebeb;
  padding: 16px 24px;
  border-radius: 20px;
  margin-top: 0;
  transition: background-color 500ms;
}

.CTA:hover {
  background-color: #dcdcdc;
  cursor: pointer;
}

#tiny-violin {
  background-image: url(../images/tinyViolinCover.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#sproat-lake {
  background-image: url(../images/sproatLakeCover.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#ubc-botany {
  background-image: url(../images/ubcBotanyCover.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

#labby {
  background-image: url(../images/labbyCover.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

#umami {
  background-image: url(../images/umamiCover.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

#kudocoin {
  background-image: url(../images/kudocoinCover.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

/* Case study specific styles */
.project-header {
  margin: 0 20px 0 20px;
}
.project-info {
  padding: 20vh 0 15vh 0;
}

.project-title {
  grid-column: span 6;
  margin: 0 10%;
}

.project-name {
  font-size: 4.5rem;
}

.cover-img {
  border-radius: 20px;
  max-width: 100%;
  max-height: 100%;
  margin-bottom: 20px;
}

.project-details {
  margin: 100px 10%;
}

.description {
  grid-column: span 6;
  font-size: 1.6rem;
  margin-bottom: 100px;
}

.bold-text {
  font-weight: 600;
}

.focus-text {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px;
  justify-content: center;
  font-size: 1.4rem;
  font-weight: 600;
}

.center-text {
  text-align: center;
  font-size: 1.2rem;
  margin: 200px 0 200px 0;
}

.info-list {
  grid-column: 10 / span 3;
}

.roles-list, .links-list {
  font-size: 20px;
  font-weight: 500;
}

.links-list {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.links-list a {
  width: fit-content;
  margin: 6px 0;
  border-bottom: #1a1a1a 1.5px solid;
  transition: 0.3s;
}

.links-list a:hover {
  font-size: 24px;
  margin: 3.25px 0;
  border-bottom: #1a1a1a 1.5px solid;
  transition: 0.3s;
}

.project-img {
  max-width: 100%;
  display: block;
  border-radius: 20px;
}

.section-text {
  grid-column: span 6;
}

.text-heading {
  font-weight: 700;
}

@media only screen and (max-width: 1224px) {
  .hero-intro {
    font-size: 1.3rem;
    width: 80%;
  }

  .card-1, .card-2, .card-3, .card-4, .card-5, .card-6, .card-7 {
    grid-column: span 12;
  }
  
  .card-info {
    opacity: 1;
    bottom: 0px;
  }

}